import React from "react"
import { Container } from "react-bootstrap"
import Layout from "../components/layout"
import CookiesPolicyText from "../components/markdown/cookies-policy"
import NavBar from "../components/navBar"
import Seo from "../components/seo"
import Footer from "../components/footer"

function CookiesPolicyPage({ location }) {
  return (
    <Layout>
      <Seo title="Cookies Policy" />
      <NavBar location={location} />
      <Container className="pb-5">
        <CookiesPolicyText />
        <Footer />
      </Container>
    </Layout>
  )
}

export default CookiesPolicyPage
